import { getPart } from "apis/vk/partsCatalog";
import graphql from "babel-plugin-relay/macro";
import Either from "data.either";
import Maybe from "data.maybe";
import { List, Map, OrderedMap, Seq, Set, fromJS, mergeDeep } from "immutable";
import {
  CODE_TYPES,
  makeCredentialFromJson,
  makeCredentialJson,
  makePersonFromJson,
  makePersonJson,
  makeUserCodeFromJson,
  makeUsers,
  makeXf6UserCodeJson,
  makeXrUserCodeJson,
  makeXtUserCodeJson,
  userCodeErrorsFromJson,
} from "models/systemUser";
import { newUserPermission as newAppUserPermission } from "models/user";
import {
  __,
  always,
  compose,
  converge,
  either,
  equals,
  identity,
  isEmpty,
  isNil,
  length,
  map,
  not,
  path,
  prop,
  toString,
} from "ramda";
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  takeLatest,
} from "redux-saga/effects";
import { fetchQuery } from "relay-runtime";
import resolvedInvalidUserCodeEventsResource from "resources/resolved-invalid-user-code-events";
import { toCustomerId } from "securecom-graphql/client";
import { authenticatedRequest } from "store/auth/sagas";
import { selectAuthToken } from "store/auth/selectors";
import { selectAuthTokenForSystem } from "store/common/selectors";
import {
  registerDefaultNotification,
  registerWarningNotification,
} from "store/notifications/actions";
import { clear as clearPendingConfirm } from "store/pendingConfirm/actions";
import * as UsersManager from "store/users/manager";
import {
  createAutoGeneratedUser as createAutoGeneratedAppUser,
  getAll as getAllAppUsers,
  removePanelPermissions,
  update as updateAppUser,
} from "store/users/sagas";
import {
  selectUserByEmail as selectAppUserByEmail,
  selectUserByVisibleLogin as selectAppUserByVisibleLogin,
} from "store/users/selectors";
import {
  capitalize,
  immutableDelete,
  immutableGet,
  immutableHas,
  immutableIndexBy,
  immutableSet,
  immutableUpdate,
} from "utils";
import { odataEndpoint, vkEndpoint } from "utils/endpoints";
import injectSaga from "utils/injectSaga";
import { responseJson } from "utils/sagas";
import uuid from "uuid/v1";
import {
  receiveErrors,
  receiveStoredUserCodes,
  receiveUsers,
  userDeleted,
  userSaved,
  userSavedWithCodeErrors,
} from "../actions/users";
import {
  DELETE_USER,
  LOAD_STORED_USER_CODES,
  REFRESH_USERS,
  REQUEST_ALL_USERS_DATA,
  REQUEST_DATA_FOR_INCLUDED_SYSTEMS,
  REQUEST_USERS,
  RESET_PASSWORD,
  SAVE_USER,
  TOGGLE_APP_USER_ACCESS,
} from "../constants/users";
import messages from "../messages/users";
import {
  selectIsAreaSystem,
  selectIsSingleAreaSystem,
  selectIsXf,
  selectIsXt,
  selectSystem,
  selectUserCode,
} from "../selectors";
import {
  selectAppUser,
  selectUser,
  selectUserFullName,
  validNumbersForSystem,
} from "../selectors/users";
import {
  getAll as getAllAreas,
  refreshFromPanel as refreshAreasFromPanel,
} from "./areas";
import { authenticatedSystemRequest } from "./index";
import { trackJob } from "./jobs";
import { createRefreshableSaga, makePanelRequest } from "./middlewares";
import {
  getAll as getAllProfiles,
  refreshFromPanel as refreshProfilesFromPanel,
} from "./profiles";

export const CELL_COM_MINI_AREAS = List.of(1);

export const foldById = immutableIndexBy(immutableGet("id"));
export const foldByNumber = immutableIndexBy(immutableGet("number"));

const responseHasUserCodes = compose(not, isEmpty, length, prop("data"));

function* getAllOdataResults(url, authToken) {
  const { data } = yield call(authenticatedRequest, {
    url,
    parseResponse: true,
    authToken,
  });
  const nextLink = data["@odata.nextLink"];

  if (nextLink) {
    const nextValue = yield call(getAllOdataResults, nextLink, authToken);
    return data.value.concat(nextValue);
  }

  return data.value;
}

function* registerUserCodeMutationErrors(
  systemId,
  details,
  fallbackMessage,
  errorKey
) {
  if (details?.message) {
    yield put(
      receiveErrors(
        systemId,
        Map({
          id: `api-${errorKey}-error`,
          defaultMessage: details.message,
        })
      )
    );

    return yield put(
      registerWarningNotification({
        id: `api-${errorKey}-error`,
        message: details?.message,
      })
    );
  } else if (typeof details === "object") {
    //For API error messages that come in as objects with arrays of errors
    const errorMessages = Object.values(details);
    const errorKey = Object.keys(details);

    const formattedErrArr = errorMessages.map(
      (message) => `${errorKey} ${message}`
    );

    const combinedErrorString = capitalize()(formattedErrArr.join(", "));

    yield put(
      receiveErrors(
        systemId,
        Map({
          id: `api-${errorKey}-error`,
          defaultMessage: combinedErrorString,
        })
      )
    );

    return yield put(
      registerWarningNotification({
        id: `api-${errorKey}-error`,
        message: combinedErrorString,
      })
    );
  } else {
    yield put(
      receiveErrors(
        systemId,
        Map({
          id: fallbackMessage.id,
          message: fallbackMessage.defaultMessage,
        })
      )
    );

    return yield put(
      registerWarningNotification({
        id: fallbackMessage.id,
        message: fallbackMessage.defaultMessage,
      })
    );
  }
}

function* registerSaveUserCodeErrors(systemId, details) {
  return yield registerUserCodeMutationErrors(
    systemId,
    details,
    messages.userCodeSaveError,
    "save"
  );
}

function* registerDeleteUserCodeErrors(systemId, details) {
  return yield registerUserCodeMutationErrors(
    systemId,
    details,
    messages.userCodeDeleteError,
    "delete"
  );
}

export const userCodesApi = {
  baseUrl: (systemId) => vkEndpoint(`/v2/panels/${systemId}/user_codes`),
  getAll: function* getAllUserCodesApi({ systemId }) {
    const authToken = yield select(selectAuthTokenForSystem, { systemId });
    return yield call(
      getAllOdataResults,
      odataEndpoint(`/api/v1/panels(${systemId})/vk.GetUserCodes()`),
      authToken
    );
  },
  create: function* createUserCodesApi({
    systemId,
    userCode,
    invalidCode,
    useCredential,
    isMobileCredential,
    personId,
  }) {
    return yield call(authenticatedSystemRequest, {
      systemId,
      url: `${userCodesApi.baseUrl(
        systemId
      )}?inv_code=${!!invalidCode}&use_credential=${!!useCredential}`,
      parseResponse: true,
      requestOptions: {
        method: "POST",
        body: {
          user_code: userCode,
          generate_mobile_credential: isMobileCredential
            ? {
                type: "customer",
                person_id: personId,
              }
            : undefined,
        },
      },
    });
  },
  update: function* updateUserCodesApi({ systemId, userCode }) {
    return yield call(authenticatedSystemRequest, {
      systemId,
      url: `${userCodesApi.baseUrl(systemId)}/${userCode.number}`,
      parseResponse: true,
      requestOptions: {
        method: "PUT",
        body: {
          user_code: userCode,
        },
      },
    });
  },
  destroy: function* destroyUserCodesApi({ systemId, number }) {
    try {
      const { data } = yield call(authenticatedSystemRequest, {
        systemId,
        url: `${userCodesApi.baseUrl(systemId)}/${number}`,
        parseResponse: true,
        requestOptions: {
          method: "DELETE",
        },
      });

      if (!data.errors) {
        return data;
      } else {
        return yield registerDeleteUserCodeErrors(systemId);
      }
    } catch (error) {
      return yield registerDeleteUserCodeErrors(systemId);
    }
  },
  refresh: function* refreshUserCodesApi({ systemId }) {
    return yield call(authenticatedSystemRequest, {
      systemId,
      url: `${userCodesApi.baseUrl(systemId)}/refresh`,
      parseResponse: true,
      requestOptions: {
        method: "POST",
      },
    });
  },
  new: function* newUserCodesApi(systemId) {
    return yield call(authenticatedSystemRequest, {
      systemId,
      url: `${userCodesApi.baseUrl(systemId)}/new`,
      parseResponse: true,
    });
  },
};

const credentialsApi = {
  showAll: function* showAllCredentials({ systemId }) {
    const authToken = yield select(selectAuthTokenForSystem, { systemId });
    return yield call(
      getAllOdataResults,
      odataEndpoint(`/api/v1/panels(${systemId})/vk.GetCredentials()`),
      authToken
    );
  },
  create: function* createCredential({ personId, credential }) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint(`/v2/persons/${personId}/credentials`),
      parseResponse: true,
      requestOptions: {
        method: "POST",
        body: { credential },
      },
    });
  },
  createPerson: function* createCredentialPerson({ credentialId, person }) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint(`/v2/credentials/${credentialId}/person`),
      parseResponse: true,
      requestOptions: {
        method: "POST",
        body: { person },
      },
    });
  },
  update: function* updateCredential(credential) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint(`/v2/credentials/${credential.id}`),
      parseResponse: true,
      requestOptions: {
        method: "PATCH",
        body: { credential },
      },
    });
  },
  destroy: function* destroyCredential({ credentialId }) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint(`/v2/credentials/${credentialId}`),
      parseResponse: true,
      requestOptions: {
        method: "DELETE",
      },
    });
  },
  destroyMobileCred: function* destroyCredential({ credentialId, systemId }) {
    const authToken = yield select(selectAuthToken);
    const authUserCode = yield select(selectUserCode, { systemId });

    try {
      const { data } = yield call(authenticatedRequest, {
        url: vkEndpoint(
          `/v2/credentials/${credentialId}/delete_mobile_credential`
        ),
        parseResponse: true,
        requestOptions: {
          method: "DELETE",
        },
        authToken: authToken,
        authUserCode: authUserCode,
      });

      if (!data.errors) {
        return data;
      } else {
        return yield registerDeleteUserCodeErrors(systemId);
      }
    } catch (error) {
      return yield registerDeleteUserCodeErrors(systemId);
    }
  },
};

const personsApi = {
  getAll: function* ({ systemId }) {
    const authToken = yield select(selectAuthTokenForSystem, { systemId });
    return yield call(
      getAllOdataResults,
      odataEndpoint(`/api/v1/panels(${systemId})/vk.GetPersons()`),
      authToken
    );
  },
  update: function* (person) {
    return yield call(authenticatedRequest, {
      url: vkEndpoint(`/v2/persons/${person.id}`),
      parseResponse: true,
      requestOptions: {
        method: "PATCH",
        body: { person },
      },
    });
  },
};

const personNotesApi = (() => {
  let cache;

  const normalizeResults = (results) =>
    Seq(results)
      .map(prop("person_note"))
      .groupBy(prop("person_id"))
      .map((notes) => notes.toArray())
      .toObject();

  function* getAllNotesByPersonId(
    authToken,
    acc = [],
    page = 1,
    pageSize = 100
  ) {
    if (cache !== undefined) {
      return cache;
    }

    const result = yield call(authenticatedRequest, {
      // This endpoint gives you person notes for your customer based on your auth token
      url: vkEndpoint(`/v2/person_notes?page=${page}&page_size=${pageSize}`),
      parseResponse: true,
      authToken,
    });

    if (!result.ok) {
      cache = normalizeResults(acc);
    } else if (result.data.length < pageSize) {
      cache = normalizeResults(acc.concat(result.data));
    } else {
      return yield call(
        getAllNotesByPersonId,
        authToken,
        acc.concat(result.data),
        page + 1,
        pageSize
      );
    }

    return Map();
  }

  return {
    getAllNotesByPersonId,
    create: function* (personNote) {
      cache = undefined;
      return yield call(authenticatedRequest, {
        url: vkEndpoint("/v2/person_notes"),
        parseResponse: true,
        requestOptions: {
          method: "POST",
          body: { person_note: personNote },
        },
      });
    },
    update: function* (personNote) {
      cache = undefined;
      return yield call(authenticatedRequest, {
        url: vkEndpoint(`/v2/person_notes/${personNote.id}`),
        parseResponse: true,
        requestOptions: {
          method: "PUT",
          body: { person_note: personNote },
        },
      });
    },
    delete: function* (id) {
      cache = undefined;
      return yield call(authenticatedRequest, {
        url: vkEndpoint(`/v2/person_notes/${id}`),
        parseResponse: true,
        requestOptions: {
          method: "DELETE",
        },
      });
    },
  };
})();

function* getUserInStore({ systemId, user }) {
  return yield select(selectUser, {
    systemId,
    userId: user.get("id"),
    codeId: user.get("codes").first().get("id"),
  });
}

export function* getCodesToDestroy({ user, systemId }) {
  const userInStore = yield call(getUserInStore, { systemId, user });

  if (!userInStore) {
    return List();
  }

  return userInStore
    .get("codes")
    .filterNot(
      compose(
        immutableHas(__, user.get("codes").map(immutableGet("id")).toSet()),
        immutableGet("id")
      )
    );
}

function* getCodesToSave({ user, systemId }) {
  const userInStore = yield call(getUserInStore, { systemId, user });

  if (!userInStore) {
    return user.get("codes");
  }

  const currentUserCodesById = immutableIndexBy(immutableGet("id"))(
    userInStore.get("codes")
  );
  const changedCodes = user
    .get("codes")
    .filter(
      either(
        compose(isNil, immutableGet("id")),
        compose(
          not,
          converge(equals, [
            compose(immutableGet(__, currentUserCodesById), immutableGet("id")),
            identity,
          ])
        )
      )
    );

  return !user.get("id") && changedCodes.isEmpty()
    ? user.get("codes").take(1)
    : changedCodes;
}

const transformUserCodesJson = compose(foldByNumber, map(makeUserCodeFromJson));

export function* refreshUserCodes({ systemId }) {
  const { data } = yield call(userCodesApi.refresh, { systemId });
  yield call(trackJob, systemId, data);
  return yield call(userCodesApi.getAll, { systemId });
}

export function* getUserCodes({ systemId, refresh }) {
  const json = yield refresh
    ? call(refreshUserCodes, { systemId })
    : call(
        createRefreshableSaga(refreshUserCodes, responseHasUserCodes),
        userCodesApi.getAll,
        { systemId }
      );

  const system = yield select(selectSystem, { systemId });
  const validNumbers = validNumbersForSystem(system);

  return transformUserCodesJson(
    Seq(json).filter(({ number }) => validNumbers.has(parseInt(number, 10)))
  );
}

export function* getAllUserCodes({
  systemId,
  extendPanelConnection = true,
  refresh,
}) {
  return yield extendPanelConnection
    ? call(makePanelRequest, systemId, getUserCodes, { systemId, refresh })
    : call(getUserCodes, { systemId, refresh });
}

export function* getCredentials({ systemId }) {
  const credentials = yield call(credentialsApi.showAll, { systemId });
  const system = yield select(selectSystem, { systemId });
  const isXf = system.get("isXf");

  //Hard coding the credential_type to "pin" for now because XF6 panels supposedly should only have pin type codes.
  //Odata is returning unknown type codes and may need to be changed later.
  if (isXf) {
    const xfCredentials = credentials.map((credential) => ({
      ...credential,
      credential_type: "pin",
    }));
    return foldById(Seq(xfCredentials).map(makeCredentialFromJson));
  }

  return foldById(Seq(credentials).map(makeCredentialFromJson));
}

function* getCredential({ credentialId }) {
  const { data } = yield call(authenticatedRequest, {
    url: vkEndpoint(`/v2/credentials/${credentialId}`),
    parseResponse: true,
  });

  return makeCredentialFromJson(
    Maybe.fromNullable(data[0])
      .map(prop("credential"))
      .getOrElse({ id: parseInt(credentialId, 10) })
  );
}

export function* getPersons({ systemId }) {
  const [persons, notesByPersonId] = yield all([
    call(personsApi.getAll, { systemId }),
    call(personNotesApi.getAllNotesByPersonId),
  ]);

  return foldById(
    Seq(persons)
      .map((person) => ({ ...person, note: notesByPersonId[person.id]?.[0] }))
      .map(makePersonFromJson)
  );
}

export function* getPerson({ personId, authToken }) {
  const [{ data }, notesByPersonId] = yield all([
    call(authenticatedRequest, {
      url: vkEndpoint(`/v2/persons/${personId}`),
      parseResponse: true,
      authToken,
    }),
    call(personNotesApi.getAllNotesByPersonId, authToken),
  ]);
  const { person } = data[0];
  return makePersonFromJson({
    ...person,
    note: notesByPersonId[person.id]?.[0],
  });
}

export function* getAllUsers({ systemId, extendPanelConnection, refresh }) {
  const [userCodes, credentials, persons] = yield all([
    call(getAllUserCodes, {
      systemId,
      extendPanelConnection,
      refresh,
    }),
    call(getCredentials, { systemId }),
    call(getPersons, { systemId }),
  ]);

  // userCodes from the panel can be newer than the oData cache.
  // Fetching new credentials to ensure credentials are up to date.
  const newCredentials = yield all(
    userCodes
      .filter(
        (userCode) =>
          userCode.get("credentialId") &&
          !credentials.has(userCode.get("credentialId"))
      )
      .toArray()
      .map(([, userCode]) =>
        getCredential({ credentialId: userCode.get("credentialId") })
      )
  );

  return makeUsers({
    persons,
    userCodes,
    credentials: credentials.merge(foldById(Seq(newCredentials))),
    timestamp: Date.now(),
  });
}

export function* requestUsers({
  systemId,
  extendPanelConnection = true,
  refresh,
}) {
  try {
    const users = yield call(getAllUsers, {
      systemId,
      extendPanelConnection,
      refresh,
    });
    yield put(receiveUsers(systemId, users.byUserId, users.orphanedCodesById));
  } catch (error) {
    yield put(receiveErrors(systemId, fromJS(error)));
  }
}

export function* requestAllUsersData({
  systemId,
  extendPanelConnection = true,
  refresh,
}) {
  try {
    const system = yield select(selectSystem, { systemId });
    const isAreaSystem = yield select(selectIsAreaSystem, { systemId });
    const isXR = system.get("isXR");
    const isXT = system.get("isXT");

    const { users } = yield all({
      profiles:
        isXR &&
        (refresh
          ? call(refreshProfilesFromPanel, { systemId })
          : call(getAllProfiles, {
              systemId,
              extendPanelConnection,
              serverRefresh: true,
            })),
      areas:
        isXT &&
        isAreaSystem &&
        (refresh
          ? call(refreshAreasFromPanel, { systemId })
          : call(getAllAreas, { systemId, refresh })),
      users: call(getAllUsers, {
        systemId,
        extendPanelConnection,
        refresh,
      }),
    });

    yield put(receiveUsers(systemId, users.byUserId, users.orphanedCodesById));
  } catch (error) {
    yield put(receiveErrors(systemId, fromJS(error)));
  }
}

export function* refreshUsers({ systemId }) {
  yield call(requestAllUsersData, { systemId, refresh: true });
}

function* destroyCode({ code, systemId, user }) {
  const isMobileCredential = code.get("type") === CODE_TYPES.MOBILE;

  if (isMobileCredential) {
    const data = yield call(credentialsApi.destroyMobileCred, {
      credentialId: code.get("id"),
      isMobileCredential: code.get("type") === CODE_TYPES.MOBILE,
      systemId: systemId,
    });

    if (!isEmpty(data) && !data.errors) {
      const job = yield call(trackJob, systemId, data);

      if (job.value.status === "error") {
        return yield registerDeleteUserCodeErrors(systemId, job.value.details);
      } else {
        yield put(userDeleted(systemId, user));
        yield put(clearPendingConfirm());
        return job;
      }
    } else {
      return yield registerDeleteUserCodeErrors(systemId);
    }
  } else {
    const data = yield call(makePanelRequest, systemId, userCodesApi.destroy, {
      systemId,
      number: code.get("number"),
    });

    if (!isEmpty(data) && !data.errors) {
      const job = yield call(trackJob, systemId, data);

      if (job.value.status === "error") {
        yield registerDeleteUserCodeErrors(systemId, job.value.details);
      } else {
        yield spawn(credentialsApi.destroy, {
          credentialId: code.get("id"),
        });

        yield put(userDeleted(systemId, user));
        yield put(clearPendingConfirm());

        return job;
      }
    } else {
      return yield registerDeleteUserCodeErrors(systemId);
    }
  }
}

function* destroyCodes({ systemId, codes, user }) {
  yield all(
    codes
      .valueSeq()
      .map((code) => call(destroyCode, { systemId, code, user }))
      .toArray()
  );
}

function* saveUserCode({
  apiCall,
  systemId,
  code,
  invalidCode,
  useCredential,
}) {
  const { data } = yield call(apiCall, {
    userCode: yield call(makeUserCodeJson, {
      systemId,
      code,
    }),
    systemId,
    invalidCode,
    useCredential,
    isMobileCredential: code.get("type") === CODE_TYPES.MOBILE,
    personId: code.get("userId"),
  });

  if (!isEmpty(data) && !data.errors) {
    const job = yield call(trackJob, systemId, data);

    if (job.value.status === "error") {
      return yield registerSaveUserCodeErrors(systemId, job.value.details);
    } else {
      return job;
    }
  } else if (data.errors) {
    return yield registerSaveUserCodeErrors(systemId, data.errors);
  } else {
    return yield registerSaveUserCodeErrors(systemId);
  }
}

function* createUserCode({
  code,
  systemId,
  invalidCode,
  useCredential = false,
}) {
  if (code.get("useFirstAvailableNumber")) {
    const { ok, data } = yield call(userCodesApi.new, systemId);
    if (ok && !isEmpty(data) && !data.errors) {
      const codeWithUpdatedNumber = code.set(
        "number",
        parseInt(data.user_code.number, 10)
      );
      yield call(saveUserCode, {
        code: codeWithUpdatedNumber,
        systemId,
        apiCall: userCodesApi.create,
        invalidCode,
        useCredential,
      });
      return codeWithUpdatedNumber;
    } else if (data.errors) {
      return yield registerSaveUserCodeErrors(systemId, data.errors);
    } else {
      return yield registerSaveUserCodeErrors(systemId);
    }
  } else {
    yield call(saveUserCode, {
      code,
      systemId,
      apiCall: userCodesApi.create,
      invalidCode,
      useCredential,
    });
    return code;
  }
}

function* updateUserCode(action) {
  yield call(saveUserCode, {
    ...action,
    apiCall: userCodesApi.update,
  });
}

export function* updatePerson({ user }) {
  const { ok } = yield call(personsApi.update, makePersonJson(user));
  if (ok) {
    if (user.getIn(["note", "note"])) {
      // If user notes has an existing id, update the note.
      if (user.getIn(["note", "id"])) {
        yield call(personNotesApi.update, {
          note: user.getIn(["note", "note"]),
          person_id: user.get("id"),
          id: user.getIn(["note", "id"]),
        });
        // If the user note doesn't have an id, create a new note.
      } else {
        yield call(personNotesApi.create, {
          note: user.getIn(["note", "note"]),
          person_id: user.get("id"),
        });
      }
    } else if (user.getIn(["note", "id"])) {
      yield call(personNotesApi.delete, user.getIn(["note", "id"]));
    }

    return yield call(getPerson, { personId: user.get("id") });
  }

  return Map({
    errors: {
      ...messages.couldNotUpdateEmail,
      values: {
        email: user.get("visibleLogin"),
      },
    },
  });
}

function* makeUserCodeJson({ systemId, code }) {
  const isXT = yield select(selectIsXt, { systemId });
  const isXF = yield select(selectIsXf, { systemId });
  return isXT
    ? makeXtUserCodeJson(code)
    : isXF
    ? makeXf6UserCodeJson(code)
    : makeXrUserCodeJson(code);
}

export function* updateCredential({ code }) {
  return yield call(credentialsApi.update, makeCredentialJson(code));
}

export function* createCode({ systemId, code, invalidCode }) {
  try {
    const userCodeSentToApi = yield call(createUserCode, {
      code,
      systemId,
      invalidCode,
    });
    const userCodesFromServer = yield call(getAllUserCodes, {
      systemId,
    });
    const createdUserCode = Seq(userCodesFromServer)
      .reverse()
      .find(
        compose(equals(userCodeSentToApi.get("number")), immutableGet("number"))
      );
    const codeWithId = code.withMutations(
      compose(
        immutableSet("id", createdUserCode.get("credentialId")),
        immutableSet("number", createdUserCode.get("number"))
      )
    );
    yield call(updateCredential, { code: codeWithId });

    return codeWithId;
  } catch (error) {
    return fromJS({ errors: userCodeErrorsFromJson(error) });
  }
}

export function* updateCode({ systemId, code }) {
  try {
    yield all({
      credential: call(updateCredential, { code }),
      userCode: call(updateUserCode, { code, systemId }),
    });

    return code;
  } catch (error) {
    return fromJS({ errors: userCodeErrorsFromJson(error) });
  }
}

function* saveCodes({ systemId, codes, customerId }) {
  const codeKeys = codes.keySeq().toList();
  const codeValues = codes.valueSeq().toList();
  const results = {};

  for (let i = 0; i < codeValues.size; i++) {
    const code = codeValues.get(i);
    results[codeKeys.get(i)] = yield call(
      !code.get("id") ? createCode : updateCode,
      { systemId, code, customerId }
    );
  }

  return fromJS(
    Seq(results).reduce(
      // eslint-disable-next-line no-confusing-arrow
      (accumulation, result, key) =>
        result.has("errors")
          ? accumulation.setIn(["errors", key], result.get("errors"))
          : accumulation.set(key, result),
      Map()
    )
  );
}

function* associateNewAppUserToPerson({ user, appUser, customerId }) {
  try {
    const createdAppUser = yield call(UsersManager.create, {
      user: appUser
        .merge({
          personId: user.get("id") || appUser.get("personId"),
          firstName: user.get("firstName"),
          lastName: user.get("lastName"),
        })
        .toJS(),
      customerId,
    });

    yield call(getAllAppUsers, { refresh: true, customerId });

    return Either.of(
      user.withMutations(
        compose(
          immutableSet("id", createdAppUser.personId),
          immutableUpdate(
            "codes",
            map(immutableSet("userId", createdAppUser.personId))
          )
        )
      )
    );
  } catch (error) {
    return Either.Left(
      Map({ appUser: Object.values(error.response.data.errors)[0][0] })
    );
  }
}

export function* associateAppUserToPerson({
  user,
  appUser,
  systemIds,
  customerId,
}) {
  const existingAppUser = yield select(selectAppUserByVisibleLogin, {
    visibleLogin: appUser.get("email"),
  });

  if (!existingAppUser) {
    return yield call(associateNewAppUserToPerson, {
      user,
      appUser,
      customerId,
    });
  }

  if (existingAppUser.get("personId") !== user.get("id")) {
    yield call(updateAppUser, {
      user: existingAppUser.withMutations(
        compose(
          immutableUpdate("userPermissions", (permissions) =>
            systemIds
              .toSeq()
              .map(toString)
              .reduce(
                (acc, systemId) =>
                  !acc.has(systemId) &&
                  appUser.get("userPermissions").has(systemId)
                    ? acc.set(
                        systemId,
                        newAppUserPermission({ panelId: systemId })
                      )
                    : acc.has(systemId) &&
                      !appUser.get("userPermissions").has(systemId)
                    ? acc.delete(systemId)
                    : acc,
                permissions
              )
          ),
          immutableSet("firstName", user.get("firstName")),
          immutableSet("lastName", user.get("lastName"))
        )
      ),
      userBeforeEdits: existingAppUser,
    });
    return Either.of(
      user.withMutations(
        compose(
          immutableSet("id", existingAppUser.get("personId")),
          immutableUpdate(
            "codes",
            map(immutableSet("userId", existingAppUser.get("personId")))
          )
        )
      )
    );
  }

  yield call(updateAppUser, {
    user: appUser.withMutations(
      compose(
        immutableSet("personId", user.get("id")),
        immutableSet("firstName", user.get("firstName")),
        immutableSet("lastName", user.get("lastName"))
      )
    ),
    userBeforeEdits: appUser,
  });
  return Either.of(user);
}

export function* updateUser({ systemId, user, customerId }) {
  const { personResult, codesResult } = yield all({
    personResult: call(updatePerson, { user }),
    codesResult: call(saveCodes, {
      codes: user.get("codes").map(immutableSet("userId", user.get("id"))),
      systemId,
      customerId,
    }),
  });

  if (personResult && personResult.get("errors")) {
    return Map({
      errors: Map({
        appUser: personResult.get("errors"),
        codes: codesResult.get("errors"),
      }),
      codes: codesResult.delete("errors"),
    });
  }

  return user
    .merge(
      codesResult.get("errors")
        ? Map({ errors: Map({ codes: codesResult.get("errors") }) })
        : Map()
    )
    .set("codes", codesResult.delete("errors"));
}

export function* createUser({
  systemId,
  user,
  appUser,
  fromAdmin = false,
  invalidCode,
  customerId,
}) {
  let partitionedCodes = user.get("codes").reduce(
    // eslint-disable-next-line no-confusing-arrow
    (accumulation, item, key) =>
      item.get("id")
        ? accumulation.update("existing", immutableSet(key, item))
        : accumulation.update("new", immutableSet(key, item)),
    Map({ existing: OrderedMap(), new: OrderedMap() })
  );

  if (partitionedCodes.get("existing").isEmpty()) {
    const firstCodeKey = partitionedCodes.get("new").keySeq().first();
    const result = yield call(createCode, {
      code: partitionedCodes.getIn(["new", firstCodeKey]),
      appUser,
      systemId,
      invalidCode,
      customerId,
    });
    if (result.get("errors")) {
      return Map({
        errors: Map({
          codes: Map({
            [firstCodeKey]: result.get("errors"),
          }),
        }),
      });
    }

    partitionedCodes = partitionedCodes.withMutations(
      compose(
        immutableUpdate("existing", immutableSet(firstCodeKey, result)),
        immutableUpdate("new", immutableDelete(firstCodeKey))
      )
    );
  }

  const credentialId = partitionedCodes.get("existing").last().get("id");
  yield call(credentialsApi.createPerson, {
    person: makePersonJson(user),
    credentialId,
  });
  const credentialWithPersonId = yield call(getCredential, {
    credentialId,
  });
  const userId = credentialWithPersonId.get("userId");

  if (user.getIn(["note", "note"])) {
    yield call(personNotesApi.create, {
      person_id: userId,
      note: user.getIn(["note", "note"]),
    });
  }

  const { personResult, codesResult } = yield all({
    personResult: call(getPerson, { personId: userId }),
    codesResult: call(saveCodes, {
      systemId,
      appUser,
      codes: partitionedCodes
        .get("existing")
        .merge(partitionedCodes.get("new"))
        .map(immutableSet("userId", userId)),
    }),
  });

  return personResult
    .merge(
      codesResult.get("errors")
        ? Map({ errors: Map({ codes: codesResult.get("errors") }) })
        : Map()
    )
    .set("codes", codesResult.delete("errors"))
    .set("personCreated", !personResult.get("codes"));
}

export function* destroyRemovedCodes({ systemId, user }) {
  const codesToDestroy = yield call(getCodesToDestroy, { systemId, user });
  if (!codesToDestroy.isEmpty()) {
    yield call(destroyCodes, { systemId, codes: codesToDestroy });
  }

  return codesToDestroy;
}

export function* filterUserCodesForSaving({ systemId, user }) {
  return user.set("codes", yield call(getCodesToSave, { systemId, user }));
}

export const setAreasForCellComMini = immutableSet(
  "areas",
  CELL_COM_MINI_AREAS
);

export function* ensureCorrectAreasForUserCodes({ systemId, user }) {
  const isXT = yield select(selectIsXt, { systemId });
  const isSingleAreaSystem = yield select(selectIsSingleAreaSystem, {
    systemId,
  });

  // MiniCellCom requires user_code areas to always include area 1 only
  return isXT && isSingleAreaSystem
    ? user.update("codes", map(setAreasForCellComMini))
    : user;
}

export function* associatePhotoWithPerson(personId, file, authToken) {
  const url = vkEndpoint(`/v2/persons/${personId}/photo`);

  const body = new FormData();
  body.append("photo[file]", file);

  const response = yield call(fetch, url, {
    method: "PUT",
    body,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (response.ok) {
    return Either.Right(true);
  }

  const json = yield call(responseJson, response);
  return Either.Left(json);
}

const callFetchQuery = ({ env, query, params }) =>
  fetchQuery(env, query, params, { force: true });

export function* saveUser({
  systemId,
  user,
  appUser,
  pendingPhotoChange,
  customerId,
  invalidCode,
  relayEnv,
}) {
  const newMobileCredentialsCount = user
    .get("codes")
    .filter(
      (credential) =>
        credential.get("type") === CODE_TYPES.MOBILE && !credential.get("id")
    ).size;

  if (newMobileCredentialsCount > 0) {
    /** @type {import("./__generated__/usersSagasSaveUserMobileCredentialsCountQuery.graphql").usersSagasSaveUserMobileCredentialsCountQuery["response"]} */
    const { customer } = yield call(callFetchQuery, {
      env: relayEnv,
      query: graphql`
        query usersSagasSaveUserMobileCredentialsCountQuery($customerId: ID!) {
          customer: node(id: $customerId) {
            ... on Customer {
              availableMobileCredentials
            }
          }
        }
      `,
      params: { customerId: toCustomerId(customerId) },
    });

    if (customer?.availableMobileCredentials < newMobileCredentialsCount) {
      yield put(receiveErrors(systemId, Map({ mobileCredentials: true })));
      return;
    }
  }

  try {
    const eitherUserWithCorrectAppUserAssociation = yield appUser.get("email")
      ? call(associateAppUserToPerson, {
          systemIds: Set.of(systemId),
          user,
          appUser,
          customerId,
        })
      : Either.of(user);

    // Either<DisplayableError, user>
    const userWithCorrectAppUserAssociation = eitherUserWithCorrectAppUserAssociation.merge();

    if (eitherUserWithCorrectAppUserAssociation.isLeft) {
      yield put(receiveErrors(systemId, userWithCorrectAppUserAssociation));
      return;
    }

    let results = yield call(
      userWithCorrectAppUserAssociation.get("id") ? updateUser : createUser,
      {
        user: yield call(filterUserCodesForSaving, {
          systemId,
          user: yield call(ensureCorrectAreasForUserCodes, {
            systemId,
            user: userWithCorrectAppUserAssociation,
          }),
        }),
        systemId,
        pendingPhotoChange,
        fromAdmin: false,
        invalidCode,
        customerId,
      }
    );

    // It's important to destroy after saving codes to avoiding orphaning a person that was created through a credential
    yield call(destroyRemovedCodes, {
      systemId,
      user: userWithCorrectAppUserAssociation,
    });

    if (results.get("id") && pendingPhotoChange) {
      const authToken = yield select(selectAuthToken);
      const photoUploadResult = yield call(
        associatePhotoWithPerson,
        results.get("id"),
        pendingPhotoChange,
        authToken
      );

      results = photoUploadResult.fold(
        (errors) =>
          mergeDeep(
            results,
            Map({
              errors: Map({
                photo: path(["errors", "file", "0"], errors),
              }),
            })
          ),
        always(results)
      );
    }

    if (results.get("errors") && !results.get("errors").isEmpty()) {
      if (results.get("personCreated")) {
        yield call(requestUsers, { systemId });
        yield put(
          userSavedWithCodeErrors(
            systemId,
            results.delete("errors"),
            results.get("errors")
          )
        );
      } else {
        yield put(receiveErrors(systemId, results.get("errors")));
      }
    } else {
      yield call(requestUsers, { systemId });
      yield put(userSaved(systemId, results));
      const authToken = yield select(selectAuthToken);
      yield call(
        resolvedInvalidUserCodeEventsResource.refresh,
        authToken,
        systemId
      );
      yield put(clearPendingConfirm());
    }
  } catch (error) {
    yield put(receiveErrors(systemId, fromJS(error)));
  }
}

export function* deleteUser({ systemId, user }) {
  try {
    // The codes in the store for this user are the ones that have been persisted.
    // The codes for the user that has been passed in may include some that have been added locally,
    // which don't need to be destroyed.
    const userInStore = yield call(getUserInStore, { systemId, user });
    const appUser = yield select(selectAppUserByEmail, {
      email: user.get("visibleLogin"),
    });
    yield all([
      appUser &&
        call(removePanelPermissions, { user: appUser, systemIds: [systemId] }),
      call(destroyCodes, { systemId, codes: userInStore.get("codes"), user }),
      user.getIn(["note", "id"]) &&
        call(personNotesApi.delete, user.getIn(["note", "id"])),
    ]);
  } catch (error) {
    yield put(receiveErrors(systemId, fromJS(error)));
  }
}

export function* resetPassword({ systemId, personId }) {
  const authToken = yield select(selectAuthTokenForSystem, { systemId });
  const { ok } = yield call(authenticatedRequest, {
    url: vkEndpoint(`/v2/persons/${personId}/reset_access`),
    authToken,
    requestOptions: {
      method: "POST",
    },
  });

  const name = yield select(selectUserFullName, { systemId, userId: personId });
  if (!ok) {
    yield put(
      registerWarningNotification({
        id: uuid(),
        message: {
          ...messages.resetPasswordFailed,
          values: {
            name,
          },
        },
      })
    );
  } else {
    yield put(
      registerDefaultNotification({
        id: uuid(),
        message: {
          ...messages.resetPasswordSucceeded,
          values: {
            name,
          },
        },
      })
    );
  }
}

export function* toggleAppUserAccess({ systemId, personId, customerId }) {
  const appUser = yield select(selectAppUser, { systemId, userId: personId });

  if (appUser) {
    yield call(updateAppUser, {
      user: appUser.hasIn(["userPermissions", systemId.toString()])
        ? appUser.deleteIn(["userPermissions", systemId.toString()])
        : appUser.setIn(
            ["userPermissions", systemId.toString()],
            newAppUserPermission({ panelId: systemId })
          ),
      userBeforeEdits: appUser,
    });
  } else {
    yield call(createAutoGeneratedAppUser, {
      panelId: systemId,
      personId,
      customerId,
    });
  }
}

export function* loadStoredUserCodes({ systemId, user, key }) {
  const authToken = yield select(selectAuthToken);
  const authUserCode = yield select(selectUserCode, { systemId });
  const results = yield all(
    user
      .get("codes")
      .valueSeq()
      .map((code) =>
        call(getPart, {
          systemId,
          number: code.get("number"),
          authToken,
          authUserCode,
        })
      )
      .toArray()
  );

  const updatedUser = user.update("codes", (codes) =>
    codes.map((code, index) => code.set("code", results[index].detail.part_num))
  );

  yield put(receiveStoredUserCodes(systemId, updatedUser, key));
}

export function* requestAllUsersDataWatcher() {
  const tasksBySystemId = {};

  while (true) {
    const action = yield take(REQUEST_ALL_USERS_DATA);

    if (tasksBySystemId[action.systemId]) {
      yield cancel(tasksBySystemId[action.systemId]);
    }

    tasksBySystemId[action.systemId] = yield fork(requestAllUsersData, action);
  }
}

export function* requestUsersWatcher() {
  const tasksBySystemId = {};

  while (true) {
    const action = yield take(REQUEST_USERS);

    if (tasksBySystemId[action.systemId]) {
      yield cancel(tasksBySystemId[action.systemId]);
    }

    tasksBySystemId[action.systemId] = yield fork(requestUsers, action);
  }
}

export function* refreshUsersWatcher() {
  yield takeLatest(REFRESH_USERS, refreshUsers);
}

export function* saveUserWatcher() {
  yield takeLatest(SAVE_USER, saveUser);
}

export function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER, deleteUser);
}

export function* requestDataForIncludedSystemsWatcher() {
  const tasksBySystemId = {};
  while (true) {
    const action = yield take(REQUEST_DATA_FOR_INCLUDED_SYSTEMS);
    const systemIds = action.systemIds.toArray();

    for (let i = 0; i < systemIds.length; i++) {
      const currentTask = tasksBySystemId[systemIds[i]];
      if (currentTask && !currentTask.isRunning()) {
        delete tasksBySystemId[systemIds[i]];
      }
      if (!tasksBySystemId[systemIds[i]]) {
        tasksBySystemId[systemIds[i]] = yield fork(requestAllUsersData, {
          systemId: systemIds[i],
          extendPanelConnection: false,
        });
      }
    }
  }
}

export function* resetPasswordWatcher() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

export function* loadStoredUserCodesWatcher() {
  const tasks = {};
  while (true) {
    const action = yield take(LOAD_STORED_USER_CODES);
    if (!tasks[action.key] || !tasks[action.key].isRunning()) {
      tasks[action.key] = yield fork(loadStoredUserCodes, action);
    }
  }
}

export function* toggleAppUserAccessWatcher() {
  yield takeLatest(TOGGLE_APP_USER_ACCESS, toggleAppUserAccess);
}

export const withRequestAllUsersDataWatcher = injectSaga({
  key: "systems/users/requestAllUsersDataWatcher",
  saga: requestAllUsersDataWatcher,
});
export const withRequestUsersWatcher = injectSaga({
  key: "systems/users/requestUsersWatcher",
  saga: requestUsersWatcher,
});
export const withRefreshUsersWatcher = injectSaga({
  key: "systems/users/refreshUsersWatcher",
  saga: refreshUsersWatcher,
});
export const withSaveUserWatcher = injectSaga({
  key: "systems/users/saveUserWatcher",
  saga: saveUserWatcher,
});
export const withDeleteUserWatcher = injectSaga({
  key: "systems/users/deleteUserWatcher",
  saga: deleteUserWatcher,
});
export const withRequestDataForIncludedSystemsWatcher = injectSaga({
  key: "systems/users/requestDataForIncludedSystemsWatcher",
  saga: requestDataForIncludedSystemsWatcher,
});
export const withResetPasswordWatcher = injectSaga({
  key: "systems/users/resetPasswordWatcher",
  saga: resetPasswordWatcher,
});
export const withToggleAppUserAccessWatcher = injectSaga({
  key: "systems/users/toggleAppUserAccessWatcher",
  saga: toggleAppUserAccessWatcher,
});
export const withloadStoredUserCodesWatcher = injectSaga({
  key: "systems/users/loadStoredUserCodesWatcher",
  saga: loadStoredUserCodesWatcher,
});

export const withAllUsersWatchers = compose(
  withRequestUsersWatcher,
  withRequestAllUsersDataWatcher,
  withRefreshUsersWatcher,
  withSaveUserWatcher,
  withDeleteUserWatcher,
  withRequestDataForIncludedSystemsWatcher,
  withResetPasswordWatcher,
  withToggleAppUserAccessWatcher,
  withloadStoredUserCodesWatcher
);

export const loadUsersDaemon = {
  name: "systems/users/requestUsersWatcher",
  saga: requestUsersWatcher,
};
export const refreshUsersDaemon = {
  name: "systems/users/refreshUsersWatcher",
  saga: refreshUsersWatcher,
};
