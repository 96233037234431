/**
 *
 * Thermostats Manager
 * @author Matt Shaffer
 *
 */

import * as thermostatsApi from "apis/vk/thermostats";
import createThermostatFromJson, {
  createThermostatsFromJson,
  validCoolingTempature,
  validHeatingTempature,
  validAutoTempature,
  getAutoCoolingPoint,
  getAutoHeatingPoint
} from "models/thermostat";
import { path, compose, head } from "ramda";
import managerError from "models/managerError";

const thermostatStatusesFromResponse = path([
  "data",
  "response",
  "thermostat_statuses"
]);

const createThermostatsFromResponse = compose(
  createThermostatsFromJson,
  thermostatStatusesFromResponse
);
const createThermostatFromResponse = compose(
  createThermostatFromJson,
  head,
  thermostatStatusesFromResponse
);

/**
 * Helper function to updating a thermostat
 */
const update = async params => {
  try {
    const response = await thermostatsApi.update(params);
    return createThermostatFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(path(["data", "error_code"], e), 10)
    });
  }
};

/**
 * Fetches all thermostat devices
 */
export const getAll = async ({ panelId }) => {
  try {
    const response = await thermostatsApi.getAll({ panelId });
    return createThermostatsFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(path(["data", "error_code"], e), 10)
    });
  }
};

/**
 * Fetches a single thermostat device
 */
export const get = async ({ panelId, number }) => {
  try {
    const response = await thermostatsApi.get({ panelId, number });
    return createThermostatFromResponse(response);
  } catch (e) {
    throw managerError({
      code: parseInt(path(["data", "error_code"], e), 10)
    });
  }
};

export const setHeatingPoint = ({
  panelId,
  number,
  temperature: setpointHeating
}) => {
  if (!validHeatingTempature(setpointHeating)) {
    throw new Error("invalid temperature");
  }

  return update({ panelId, number, setpointHeating });
};

export const setCoolingPoint = ({
  panelId,
  number,
  temperature: setpointCooling
}) => {
  if (!validCoolingTempature(setpointCooling)) {
    throw new Error("invalid temperature");
  }

  return update({ panelId, number, setpointCooling });
};

export const setAutoPoint = ({ panelId, number, temperature }) => {
  if (!validAutoTempature(temperature)) {
    throw new Error("invalid temperature");
  }

  return update({
    panelId,
    number,
    setpointCooling: getAutoCoolingPoint(temperature),
    setpointHeating: getAutoHeatingPoint(temperature)
  });
};

export const changeMode = ({ panelId, number, mode }) =>
  update({ panelId, number, mode });

export const changeFanMode = ({ panelId, number, fanMode }) =>
  update({ panelId, number, fanMode });
