/**
 *
 * Schedules Manager
 * @author Chad Watson, Kyle Ellman
 *
 *
 */
import { map } from "ramda";
import * as api from "apis/vk/schedules/xr";
import scheduleModel, { toApiObject, saveErrors } from "models/schedules/xr";
import { immediateRefreshRequest } from "utils/managers";
export const MAX_SCHEDULES = 99;
export const getAll = ({ systemId, hasTwilight, refresh: forceRefresh, now }) =>
  immediateRefreshRequest({
    apiPromiseFunc: (refresh) =>
      api.getAll({
        systemId,
        refresh: refresh || forceRefresh,
      }),
    successRetryCondition: (data) => data.length === 0,
    successCallback: map(({ time_schedule: schedule }) =>
      scheduleModel(now, hasTwilight, schedule)
    ),
  });
export const create = async ({ systemId, schedule }) => {
  try {
    return await api.create({
      systemId,
      schedule: toApiObject(schedule),
    });
  } catch ({ response }) {
    if (response) {
      throw saveErrors({
        schedule,
        errors: response.data.errors,
      });
    }

    return null;
  }
};
export const update = async ({ systemId, number, schedule }) => {
  try {
    return await api.update({
      systemId,
      number,
      schedule: toApiObject(schedule),
    });
  } catch ({ response }) {
    if (response) {
      throw saveErrors({
        schedule,
        errors: response.data.errors,
      });
    }

    return null;
  }
};
export const destroy = ({ systemId, number }) =>
  api.destroy({
    systemId,
    number,
  });

export const getUses = ({ systemId, number }) =>
  api.getUses({
    systemId,
    number,
  });
