/**
 *
 * Notifications actions
 * @author Chad Watson
 *
 *
 */
import { NOTIFICATION_TYPES } from "constants/notifications";
import { CLEAR_NOTIFICATION, REGISTER_NOTIFICATION } from "./constants";

export const registerNotification = ({
  type,
  id,
  message,
  messageValues,
  autoDismiss = true,
  includeCloseButton = false,
  payload,
}) => ({
  type: REGISTER_NOTIFICATION,
  notificationType: type,
  id,
  message,
  messageValues,
  autoDismiss,
  includeCloseButton,
  payload,
});
export const registerDefaultNotification = (props) =>
  registerNotification({
    ...props,
    type: NOTIFICATION_TYPES.DEFAULT,
  });
export const registerWarningNotification = (props) =>
  registerNotification({
    ...props,
    type: NOTIFICATION_TYPES.WARNING,
  });
export const registerErrorNotification = (props) =>
  registerNotification({
    ...props,
    type: NOTIFICATION_TYPES.ERROR,
  });
export const registerActionableNotification = (props) =>
  registerNotification({
    autoDismiss: false,
    ...props,
    type: NOTIFICATION_TYPES.ACTIONABLE,
  });
export const registerProgressNotification = (props) =>
  registerNotification({
    autoDismiss: false,
    ...props,
    type: NOTIFICATION_TYPES.PROGRESS,
  });
export const clearNotification = (id) => ({
  type: CLEAR_NOTIFICATION,
  id,
});
