/**
 *
 * Doors selectors
 * @author Chad Watson
 *
 *
 */
import {
  always,
  complement,
  compose,
  converge,
  F,
  filter,
  ifElse,
  isNil,
  prop
} from "ramda";
import { createSelector } from "reselect";
import {
  immutableGet,
  immutableIsEmpty,
  safeImmutableGet,
  safeGetter
} from "utils";
import { initialState } from "../reducer/doors";
import { selectIsAdmin } from "store/auth/selectors";
import {
  selectSystem,
  createCachedSelectorBySystemId,
  selectActiveSystemId,
  selectIsXr
} from "./index";
import { selectPermissions } from "./permissions";
import { OrderedMap } from "immutable";

const selectDoorNumberFromProps = (_, props) => props.number;

const selectSystemDoors = compose(
  ifElse(isNil, always(initialState), immutableGet("doors")),
  selectSystem
);
export const selectAllDoors = compose(
  immutableGet("byNumber"),
  selectSystemDoors
);
export const selectDoors = compose(
  filter(prop("tracked")),
  selectAllDoors
);
/**
 * Currently only XR systems have the feature to use doors in User Programmable Actions.
 *
 * As of XT 202, XT systems can now have doors, however these doors should not
 * be available for User Programmable Actions.
 *
 * Z-Wave locks and barrier operators, are NOT affected and should still display for XT.
 */

export const selectDoorsAvailableForUserProgrammableActions = createSelector(
  selectIsXr,
  selectDoors,
  (isXr, doors) => (isXr ? doors : OrderedMap())
);
export const filterDoorsToAccessibleDoors = (
  allDoors,
  permissions,
  isAdmin,
  activeSystemId
) =>
  isAdmin && isNil(activeSystemId)
    ? allDoors
    : permissions.accessibleDoors
        .map(accessibleDoors => {
          const setOfAccessibleDoors = new Set(accessibleDoors);
          return allDoors.filter(door => setOfAccessibleDoors.has(door.number));
        })
        .getOrElse(allDoors);
export const selectAccessibleDoors = createSelector(
  selectDoors,
  selectPermissions,
  selectIsAdmin,
  selectActiveSystemId,
  filterDoorsToAccessibleDoors
);
export const selectPrivateDoors = createSelector(selectAllDoors, doors =>
  doors.filter(door => door.private)
);
export const selectDoorsReceived = compose(
  immutableGet("initialStateReceived"),
  selectSystemDoors
);
export const selectHasDoors = compose(
  ifElse(isNil, F, complement(immutableIsEmpty)),
  selectDoors
);
export const selectDoor = converge(safeImmutableGet, [
  selectDoorNumberFromProps,
  selectDoors
]);
export const selectRequestingByNumber = compose(
  immutableGet("requestingByNumber"),
  selectSystemDoors
);
export const selectRefreshingStatusByNumber = compose(
  immutableGet("refreshingStatusByNumber"),
  selectSystemDoors
);
export const selectRequestingAll = compose(
  immutableGet("requestingAll"),
  selectSystemDoors
);
export const selectRefreshing = compose(
  immutableGet("refreshing"),
  selectSystemDoors
);
export const selectDoorsRequestError = compose(
  immutableGet("requestError"),
  selectSystemDoors
);
export const selectRealtimeDoors = createCachedSelectorBySystemId(
  selectDoors,
  safeGetter(filter(immutableGet("realTimeStatus")))
);
export const selectPollingForRealtimeStatuses = compose(
  immutableGet("pollingForRealtimeStatuses"),
  selectSystemDoors
);
