/**
 *
 * Systems Selector
 * @author Chad Watson
 *
 *
 */
import { CONNECTION_LENGTH_IN_MINUTES } from "constants/index";
import Maybe from "data.maybe";
import { Map, Seq } from "immutable";
import { isAdminRoute } from "paths";
import {
  always,
  anyPass,
  both,
  complement,
  compose,
  converge,
  curry,
  equals,
  filter,
  identity,
  ifElse,
  isNil,
  map,
  not,
  partial,
  prop,
  unless,
} from "ramda";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import {
  selectIsTempDealerUser,
  selectUserPermissions,
} from "store/auth/selectors";
import {
  immutableGet,
  immutableGetIn,
  immutableIsEmpty,
  immutableIsNotEmpty,
  immutableToMap,
  immutableValueSeq,
  safeImmutableGet,
} from "utils";
import { lessThanXMinutesAgo } from "utils/dates";
import {
  selectActiveSystem,
  selectLocationBeforeTransitions,
  selectSelectedCustomerIdForAdminSection,
  selectSystem,
  selectSystemIdFromProps,
  selectSystems,
  selectSystemsDomain,
  selectUsersByCustomerId,
} from "../../common/selectors";
import { PANEL_HARDWARE_MODELS, isVideoOnly } from "../models/SystemState";
import {
  getAreaStatuses,
  getArmedAreas,
  getArmedStatus,
  getArmedStatusError,
  getArmingType,
  getBadZones,
  getHasArmedStatus,
  getIsAreaArmingType,
  getIsAreaSystem,
  getRequestingAreaStatusesByNumber,
  getRequestingArmingStatus,
} from "./arming";
import {
  getCanArmInstant,
  getCanSendCodesToLocks,
  getCapabilities,
  getHasEnhancedApp,
  getIsSingleAreaSystem,
  getSendCodesToLocksNumberRange,
  getSystemSupportsInactiveUsers,
  getSystemSupportsTempSchedules,
  getSystemSupportsTwilightScheduling,
} from "./capabilities";

export {
  selectActiveSystem,
  selectActiveSystemId,
  selectSystem,
  selectSystemIdFromProps,
  selectSystems,
} from "../../common/selectors";

export const selectSiteIdFromProps = (_, props) => props.siteId;
export const systemHardwareModel = immutableGet("hardwareModel");
export const systemIsXR = immutableGet("isXR");
export const systemIsXF6 = immutableGet("isXf");
export const systemIsXT = immutableGet("isXT");
export const systemIsECP = immutableGetIn([
  "systemOptions",
  "options",
  "isECP",
]);
export const systemIsDSC = immutableGetIn([
  "systemOptions",
  "options",
  "isDSC",
]);
export const systemSoftwareVersion = (system) => system.softwareVersion; // Ideally we would have some sort of remote API that would tell us this kind of thing,
// but it doesn't exist right now.

export const systemHasRemotePanic = (system) =>
  systemSoftwareVersion(system) >= 193;
export const systemIsECPAndCanEditMasterUser = both(
  systemIsECP, // Ideally we would have some sort of remote API that would tell us this kind of thing,
  // but it doesn't exist right now.
  (system) =>
    systemSoftwareVersion(system) >= 193 ||
    (systemSoftwareVersion(system) === "192" &&
      system.softwareDate === "10/04/19")
); // Currently we are assuming the rules are the same for editing the master user on ECP and DSC panels

export const systemIsDSCAndCanEditMasterUser = both(
  systemIsDSC, // Ideally we would have some sort of remote API that would tell us this kind of thing,
  // but it doesn't exist right now.
  (system) => system.softwareVersion >= 201
);
export const systemIsXT30 = compose(
  equals(PANEL_HARDWARE_MODELS.XT30),
  systemHardwareModel
);
export const systemIsXT50 = compose(
  equals(PANEL_HARDWARE_MODELS.XT50),
  systemHardwareModel
);
export const systemIsXTLP = compose(
  equals(PANEL_HARDWARE_MODELS.XTLP),
  systemHardwareModel
);
export const systemIsXR150 = compose(
  equals(PANEL_HARDWARE_MODELS.XR150),
  systemHardwareModel
);
export const systemIsXR550 = compose(
  equals(PANEL_HARDWARE_MODELS.XR550),
  systemHardwareModel
);
export const systemIsXf500 = compose(
  equals(PANEL_HARDWARE_MODELS.XF6_500),
  systemHardwareModel
);
export const systemIsXf100 = compose(
  equals(PANEL_HARDWARE_MODELS.XF6_100),
  systemHardwareModel
);
export const systemIsCellComSL = compose(
  equals(PANEL_HARDWARE_MODELS.CellComSL),
  systemHardwareModel
);
export const systemIsCellComEX = compose(
  equals(PANEL_HARDWARE_MODELS.CellComEx),
  systemHardwareModel
);
export const systemIsIComSL = compose(
  equals(PANEL_HARDWARE_MODELS.iComSL),
  systemHardwareModel
);
export const systemIsIComLNC = compose(
  equals(PANEL_HARDWARE_MODELS.iComLNC),
  systemHardwareModel
);
export const systemIsDualCom = compose(
  equals(PANEL_HARDWARE_MODELS.DualCom),
  systemHardwareModel
);
export const systemIsMiniCellCom = compose(
  equals(PANEL_HARDWARE_MODELS.MiniCellCom),
  systemHardwareModel
);
export const systemIsTakeover = anyPass([
  systemIsCellComSL,
  systemIsIComSL,
  systemIsIComLNC,
  systemIsDualCom,
  systemIsMiniCellCom,
  systemIsCellComEX,
]);
export const systemIsX1 = compose(
  equals(PANEL_HARDWARE_MODELS.X1),
  systemHardwareModel
);
export const systemIsTakeoverAndSupportsExtendedUserCodes = both(
  anyPass([
    systemIsCellComSL,
    systemIsIComSL,
    systemIsDualCom,
    systemIsCellComEX,
  ]), // Ideally we would have some sort of remote API that would tell us this kind of thing,
  // but it doesn't exist right now.
  (system) => systemSoftwareVersion(system) >= 194
);
export const systemIsXf = anyPass([systemIsXf500, systemIsXf100]);
export const selectSystemsAsValueSeq = createSelector(
  selectSystems,
  immutableValueSeq
);
export const selectHasSystems = createSelector(
  selectSystems,
  complement(immutableIsEmpty)
);
export const selectRequestingPanels = compose(
  immutableGet("requestingPanels"),
  selectSystemsDomain
);
export const selectDefaultSystemId = createSelector(
  selectSystemsDomain,
  selectSystems,
  (substate, systems) =>
    substate.get("defaultSystemId") ||
    (!systems.isEmpty() ? systems.first().get("id") : null)
);

export const selectSiteBillingControlSystems = createSelector(
  selectSystemsDomain,
  selectSystems,
  (_, systems) =>
    systems.filter(
      (system) => system.get("isX1") && system.get("isBillingControlSystem")
    )
);
export const selectDefaultNonSiteSystemId = createSelector(
  selectSystemsDomain,
  selectSystems,
  (substate, systems) =>
    substate.get("defaultSystemId") ||
    (!systems.isEmpty() &&
    !systems.filter((system) => !system.get("isX1")).isEmpty()
      ? systems
          .filter((system) => !system.get("isX1"))
          .first()
          .get("id")
      : null)
);
export const selectDefaultSiteId = createSelector(
  selectSystemsDomain,
  selectSystems,
  (_, systems) =>
    !systems.filter((system) => system.get("isX1")).isEmpty()
      ? systems
          .filter((system) => system.get("isX1"))
          .first()
          .get("siteId")
      : null
);
export const selectDefaultSystem = converge(safeImmutableGet, [
  selectDefaultSystemId,
  selectSystems,
]);
export const selectSystemsCount = createSelector(
  selectSystems,
  ifElse(isNil, always(0), prop("size"))
);
/**
 * Selectors for individual systems
 */

export const createCachedSelectorBySystemId = (...args) =>
  createCachedSelector(...args)(selectSystemIdFromProps);
export const selectSiteBillingControlSystem = createSelector(
  selectSiteBillingControlSystems,
  selectSiteIdFromProps,
  (billingControlSystems, siteId) =>
    billingControlSystems
      .valueSeq()
      .find((system) => system.get("siteId") === Number(siteId))
);
export const selectConnecting = createSelector(
  selectSystem,
  both(compose(not, getHasArmedStatus), getRequestingArmingStatus)
);
const createSystemSelector = partial(createCachedSelectorBySystemId, [
  selectSystem,
]);
export const selectSystemName = compose(immutableGet("name"), selectSystem);
export const selectSoftwareVersion = compose(
  systemSoftwareVersion,
  selectSystem
);
export const selectSystemIsXR = compose(systemIsXR, selectSystem);
export const selectSystemIsXT = compose(systemIsXT, selectSystem);
export const selectSystemIsXF = compose(systemIsXF6, selectSystem);
export const selectSystemIsECP = compose(systemIsECP, selectSystem);
export const selectSystemIsDSC = compose(systemIsDSC, selectSystem);
export const selectSystemIsXT30 = compose(systemIsXT30, selectSystem);
export const selectSystemIsXT50 = compose(systemIsXT50, selectSystem);
export const selectSystemIsXR150 = compose(systemIsXR150, selectSystem);
export const selectSystemIsXR550 = compose(systemIsXR550, selectSystem);
export const selectSystemIsXTLP = compose(systemIsXTLP, selectSystem);
export const selectSystemIsCellComSL = compose(systemIsCellComSL, selectSystem);
export const selectSystemIsIComSL = compose(systemIsIComSL, selectSystem);
export const selectSystemIsDualCom = compose(systemIsDualCom, selectSystem);
export const selectSystemIsCellComEX = compose(systemIsCellComEX, selectSystem);
export const selectSystemWifiSetupEnabled = compose(
  immutableGet("wifiSetupEnabled"),
  selectSystem
);
export const selectHasUniviewEnabled = compose(
  immutableGet("univiewEnabled"),
  selectSystem
);
export const selectSystemIsDefaultSystem = converge(equals, [
  selectSystemIdFromProps,
  selectDefaultSystemId,
]);
export const selectSaveUserCode = compose(
  immutableGet("saveUserCode"),
  selectSystem
);
export const selectIsXt = compose(immutableGet("isXT"), selectSystem);
export const selectIsXr = compose(immutableGet("isXR"), selectSystem);
export const selectIsXf = compose(immutableGet("isXf"), selectSystem);
export const selectHardwareModel = compose(
  immutableGet("hardwareModel"),
  selectSystem
);
export const selectRequestingLockdown = compose(
  immutableGet("requestingLockdown"),
  selectSystem
);
export const selectRequestingSensorReset = compose(
  immutableGet("requestingSensorReset"),
  selectSystem
);
export const selectSystemHasRemotePanic = compose(
  ifElse(isNil, always(false), systemHasRemotePanic),
  selectSystem
);
const getUserCode = safeImmutableGet("userCode");
const getHasUserCode = compose(Boolean, getUserCode);
export const selectUserCode = compose(unless(isNil, getUserCode), selectSystem);
export const selectRequiresUserCode = compose(
  immutableGet("requiresUserCode"),
  selectSystem
);
export const selectRequestingCapabilities = compose(
  immutableGet("requestingCapabilities"),
  selectSystem
);
export const selectConnectionRefreshedAt = compose(
  immutableGet("sessionRefreshedAt"),
  selectSystem
);
export const selectServicesManager = compose(
  immutableGet("servicesManager"),
  selectSystem
);
const getTrackedOutputs = immutableGet("trackedOutputs");
export const selectTrackedOutputs = compose(getTrackedOutputs, selectSystem);
export const selectTrackedOutputsBySystem = createSelector(
  selectSystems,
  compose(immutableToMap, map(getTrackedOutputs))
);
export const selectPanelId = compose(immutableGet("panelId"), selectSystem);
export const selectHasConnectionTimeout = compose(
  immutableGet("connectionTimeout"),
  selectSystem
);
export const selectSystemsForSelectedCustomerForAdminSection = createSelector(
  selectSystems,
  selectSelectedCustomerIdForAdminSection,
  (systems, selectedCustomerIdForAdminSection) =>
    systems.filter(
      (system) => system.customerId === selectedCustomerIdForAdminSection
    )
);
export const selectAccessibleSystemsForUserCodesManagement = createSelector(
  selectSystemsForSelectedCustomerForAdminSection,
  selectUserPermissions,
  (systems, permissions) =>
    systems.filter(
      (system, systemId) =>
        (system.get("isXT") ||
          system.get("isX1") ||
          system.get("isXf") ||
          [
            PANEL_HARDWARE_MODELS.XR150,
            PANEL_HARDWARE_MODELS.XR350,
            PANEL_HARDWARE_MODELS.XR550,
          ].includes(system.get("hardwareModel"))) &&
        permissions.getIn([systemId.toString(), "multiPanelUserCodesEnabled"])
    )
);
export const selectAccessibleSystemsForSchedulesManagement = createSelector(
  selectSystemsForSelectedCustomerForAdminSection,
  selectUserPermissions,
  (systems, permissions) =>
    systems.filter(
      (system, systemId) =>
        !system.isX1 &&
        permissions.getIn([systemId.toString(), "multiPanelSchedulesEnabled"])
    )
);
export const selectAccessibleSystemsForReportsManagement = createSelector(
  selectSystems,
  selectUserPermissions,
  selectActiveSystem,
  selectSelectedCustomerIdForAdminSection,
  selectLocationBeforeTransitions,
  (
    systems,
    permissions,
    activeSystem,
    selectedCustomerIdForAdminSection,
    location
  ) => {
    const customerId = isAdminRoute(location.pathname)
      ? selectedCustomerIdForAdminSection
      : activeSystem?.customerId;
    return Seq(systems)
      .filter(
        (system, systemId) =>
          system.customerId === customerId &&
          !system.isX1 &&
          permissions.getIn([systemId.toString(), "reportsEnabled"])
      )
      .sortBy(immutableGet("name"));
  }
);
export const selectAccessibleSystemsForArming = createSelector(
  selectSystemsForSelectedCustomerForAdminSection,
  selectUserPermissions,
  (systems, permissions) =>
    systems.filter(
      (system, systemId) =>
        (!system.isX1 &&
          permissions.getIn([systemId.toString(), "armingEnabled"])) ||
        system.isXf
    )
);
export const selectAccessibleSystemsForMultiPanelProfilesManagement = createSelector(
  selectSystemsForSelectedCustomerForAdminSection,
  selectUserPermissions,
  (systems, permissions) =>
    systems.filter(
      (system, systemId) =>
        !system.isX1 &&
        permissions.getIn([systemId.toString(), "multiPanelProfilesEnabled"])
    )
);
export const selectSystemsForSystemGroupsManagement = createSelector(
  selectSystemsForSelectedCustomerForAdminSection,
  (systems) => systems.filter((system) => !system.isX1)
);
export const selectHasMultipleSites = createSelector(
  selectSystems,
  (systems) =>
    new Set(
      systems.filter((system) => system.isX1).map((system) => system.siteId)
    ).size > 1
);
export const selectHasNonSiteSystems = createSelector(
  selectSystems,
  (systems) => systems.some((system) => !system.isX1)
);
export const selectUserCanManageMultiSystemUserCodes = createSelector(
  selectAccessibleSystemsForUserCodesManagement,
  immutableIsNotEmpty
);
export const selectUserCanManageMultiPanelProfiles = createSelector(
  selectAccessibleSystemsForMultiPanelProfilesManagement,
  immutableIsNotEmpty
);
export const selectUserCanManageMultiSystemSchedules = createSelector(
  selectAccessibleSystemsForSchedulesManagement,
  immutableIsNotEmpty
);
export const selectUserCanRunReports = createSelector(
  selectAccessibleSystemsForReportsManagement,
  immutableIsNotEmpty
);
export const selectUserCanManageArming = createSelector(
  selectAccessibleSystemsForArming,
  immutableIsNotEmpty
);
export const selectSensorActivityZones = createSystemSelector(
  immutableGet("sensorActivityZones")
);
/**
 * Arming
 */

export const selectRequestingArmingStatus = createSystemSelector(
  getRequestingArmingStatus
);
export const selectArmedStatusError = createSystemSelector(getArmedStatusError);
export const selectArmedStatus = createSystemSelector(getArmedStatus);
export const selectHasArmedStatus = createSystemSelector(getHasArmedStatus);
export const selectAreaStatuses = createSystemSelector(getAreaStatuses);
export const selectArmedAreas = createSystemSelector(getArmedAreas);
export const selectRequestingAreaStatusesByNumber = createSystemSelector(
  getRequestingAreaStatusesByNumber
);
export const selectBadZones = createSystemSelector(getBadZones);
export const selectArmingType = createSystemSelector(getArmingType);
export const selectIsAreaSystem = createCachedSelectorBySystemId(
  selectSystem,
  getIsAreaArmingType
);
export const selectAreaSystems = createSelector(
  selectSystems,
  unless(isNil, filter(getIsAreaSystem))
);
/**
 * Capabilities
 */

export const selectCapabilitiesReceived = createSystemSelector(
  prop("capabilitiesReceived")
);
export const selectCapabilities = createSystemSelector(getCapabilities);
export const selectCanArmInstant = createSystemSelector(getCanArmInstant);
export const selectSystemSupportsTempSchedules = createSystemSelector(
  getSystemSupportsTempSchedules
);
export const selectHasEnhancedApp = createSystemSelector(getHasEnhancedApp);
export const selectCanSendCodesToLocks = createSystemSelector(
  getCanSendCodesToLocks
);
export const selectSendCodesToLocksNumberRange = createSystemSelector(
  getSendCodesToLocksNumberRange
);
export const selectIsSingleAreaSystem = createSystemSelector(
  getIsSingleAreaSystem
);
export const selectSystemSupportsInactiveUsers = createSystemSelector(
  getSystemSupportsInactiveUsers
);
export const selectSystemSupportsTwilightScheduling = createSystemSelector(
  getSystemSupportsTwilightScheduling
);
export const selectMaxSchedulesPerDoor = createSystemSelector(
  prop("maxSchedulesPerDoor")
);
export const selectHasLegacyTempUser = createSystemSelector(
  prop("hasLegacyTempUser")
);
/**
 * General Selectors
 */

export const getUserCodeValidated = immutableGet("userCodeValidated");

export const systemHasValidSession = (system) =>
  getHasUserCode(system) && getUserCodeValidated(system);

export const selectUserCodeValidated = createSystemSelector(
  getUserCodeValidated
);

export const getCanViewRestrictedPages = (isTempDealerUser, system) =>
  isTempDealerUser ||
  systemIsX1(system) ||
  (getHasUserCode(system) && getUserCodeValidated(system));

export const selectCanViewRestrictedPages = createCachedSelector(
  selectIsTempDealerUser,
  (_, { system }) => system,
  getCanViewRestrictedPages
)((_, { system }) => system.id);

export const selectCurrentSystemSession = createSelector(
  identity,
  selectSystems,
  (state, systems) =>
    Maybe.fromNullable(
      systems.find(
        (system) =>
          !systemIsX1(system) &&
          !systemIsXf(system) &&
          selectCanViewRestrictedPages(state, {
            system,
          })
      )
    )
);

export const hasValidSession = curry(
  (now, system) =>
    !!system.userCode &&
    system.userCodeValidated &&
    ((getHasEnhancedApp(system) && system.saveUserCode) ||
      lessThanXMinutesAgo({
        x: CONNECTION_LENGTH_IN_MINUTES,
        oldDate: new Date(system.sessionRefreshedAt),
        newDate: new Date(now),
      }))
);

export const selectIsVideoOnly = createSystemSelector(isVideoOnly);
export const selectLegacyPanelId = createSystemSelector(prop("panelId"));

export const selectSystemsBySiteId = createSelector(selectSystems, (systems) =>
  systems.reduce(
    (acc, system) => (system.siteId ? acc.set(system.siteId, system) : acc),
    Map()
  )
);
export const selectSystemWithSiteId = createSelector(
  selectSystemsBySiteId,
  (_, { siteId }) => siteId,
  (systemsBySiteId, siteId) => systemsBySiteId.get(siteId)
);
export const selectUserForSite = createSelector(
  selectSystemWithSiteId,
  selectUsersByCustomerId,
  (system, usersByCustomerId) =>
    system && usersByCustomerId.get(system.customerId)
);
